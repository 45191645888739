var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{attrs:{"flat":""}},[_c('v-card',{staticClass:"ma-1",attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.formValid)+" "),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card-text',{staticClass:"px-10"},[_c('v-row',{staticClass:"pa-0 mt-4 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1",attrs:{"id":"timeline"}},[_vm._v("Project Timeline *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.project_timeline)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-1",attrs:{"items":_vm.projectTimeline,"name":"quoteTimeline","item-text":"description","item-value":"pk","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"project-timeline","data-cy":"rfq-fixture-project-timeline","rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteTimeline,
                  vuexValue: _vm.quote.project_timeline,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'project_timeline',
                  value: _vm.formQuoteTimeline,
                })}},model:{value:(_vm.formQuoteTimeline),callback:function ($$v) {_vm.formQuoteTimeline=$$v},expression:"formQuoteTimeline"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Project Timeline Details")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.project_timeline_details)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{staticClass:"subtitle-1",attrs:{"name":"quoteTimelineDetails","type":"text","auto-grow":"","flat":"","outlined":"","solo":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"project-timeline-details","data-cy":"rfq-fixture-project-timeline-details","rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formQuoteTimelineDetails,
                  vuexValue: _vm.quote.project_timeline_details,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'project_timeline_details',
                  value: _vm.formQuoteTimelineDetails,
                })}},model:{value:(_vm.formQuoteTimelineDetails),callback:function ($$v) {_vm.formQuoteTimelineDetails=$$v},expression:"formQuoteTimelineDetails"}})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Estimated Volumes *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.estimated_volumes)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteEstimatedVolume","type":"number","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"estimated-volumes","data-cy":"rfq-fixture-estimated-volumes","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteEstimatedVolume,
                  vuexValue: _vm.quote.estimated_volumes,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'estimated_volumes',
                  value: +_vm.formQuoteEstimatedVolume,
                })}},model:{value:(_vm.formQuoteEstimatedVolume),callback:function ($$v) {_vm.formQuoteEstimatedVolume=$$v},expression:"formQuoteEstimatedVolume"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Number of Fixtures *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.number_of_fixtures)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteNumberOfFixtures","type":"number","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"number-of-fixtures","data-cy":"rfq-fixture-number-of-fixtures","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteNumberOfFixtures,
                  vuexValue: _vm.quote.number_of_fixtures,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'number_of_fixtures',
                  value: +_vm.formQuoteNumberOfFixtures,
                })}},model:{value:(_vm.formQuoteNumberOfFixtures),callback:function ($$v) {_vm.formQuoteNumberOfFixtures=$$v},expression:"formQuoteNumberOfFixtures"}})],1)],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.quote.number_of_unique_duts > 1)?_c('v-alert',{staticClass:"ma-0 subtitle-2",attrs:{"color":"warning","dense":"","outlined":"","type":"info"}},[_vm._v("A panelized fixture is only available when there is a single unique DUT in the fixture.")]):_vm._e(),_c('v-checkbox',{attrs:{"label":"Would you like a panelize fixture?","color":"secondary","disabled":_vm.quote.number_of_unique_duts > 1 || (!_vm.isQuoteIncomplete && !_vm.isAdmin),"dense":"","data-test":"check-panelize","data-cy":"rfq-fixture-check-panelize","rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formCheckPanelize,
                  vuexValue: _vm.quote.panelized_fixture,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: 'panelized_fixture',
                  value: _vm.formCheckPanelize,
                })}},model:{value:(_vm.formCheckPanelize),callback:function ($$v) {_vm.formCheckPanelize=$$v},expression:"formCheckPanelize"}})],1)],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.formCheckPanelize)?_c('div',{staticClass:"mt-15"},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("DUTs in Panel")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.duts_in_panel)}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteDutsInPanel","type":"number","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"duts-in-panel","data-cy":"rfq-fixture-duts-in-panel","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                      required: false,
                      formValue: _vm.formQuoteDutsInPanel,
                      vuexValue: _vm.quote.duts_in_panel,
                    })},on:{"input":function($event){return _vm.updateUIState({
                      key: 'duts_in_panel',
                      value: _vm.formQuoteDutsInPanel,
                    })}},model:{value:(_vm.formQuoteDutsInPanel),callback:function ($$v) {_vm.formQuoteDutsInPanel=$$v},expression:"formQuoteDutsInPanel"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("DUTs per row")])])],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.duts_per_row)}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteDutsPerRow","type":"number","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"duts-per-row","data-cy":"rfq-fixture-duts-per-row","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                              required: false,
                              formValue: _vm.formQuoteDutsPerRow,
                              vuexValue: _vm.quote.duts_per_row,
                            })},on:{"input":function($event){return _vm.updateUIState({
                              key: 'duts_per_row',
                              value: _vm.formQuoteDutsPerRow,
                            })}},model:{value:(_vm.formQuoteDutsPerRow),callback:function ($$v) {_vm.formQuoteDutsPerRow=$$v},expression:"formQuoteDutsPerRow"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("DUTs per column")])])],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.duts_per_column)}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteDutsPerColumn","type":"number","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"duts-per-column","data-cy":"rfq-fixture-duts-per-column","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                              required: false,
                              formValue: _vm.formQuoteDutsPerColumn,
                              vuexValue: _vm.quote.duts_per_column,
                            })},on:{"input":function($event){return _vm.updateUIState({
                              key: 'duts_per_column',
                              value: _vm.formQuoteDutsPerColumn,
                            })}},model:{value:(_vm.formQuoteDutsPerColumn),callback:function ($$v) {_vm.formQuoteDutsPerColumn=$$v},expression:"formQuoteDutsPerColumn"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Panel Details")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.panel_details)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{staticClass:"subtitle-1",attrs:{"name":"quotePanelDetails","type":"text","auto-grow":"","flat":"","outlined":"","solo":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"panel-details","data-cy":"rfq-fixture-panel-details","rules":_vm.hybridRules({
                      required: false,
                      formValue: _vm.formQuotePanelDetails,
                      vuexValue: _vm.quote.panel_details,
                    })},on:{"input":function($event){return _vm.updateUIState({
                      key: 'panel_details',
                      value: _vm.formQuotePanelDetails,
                    })}},model:{value:(_vm.formQuotePanelDetails),callback:function ($$v) {_vm.formQuotePanelDetails=$$v},expression:"formQuotePanelDetails"}})],1)],1)],1):_vm._e()]),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Wiring *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.fixture_wiring)}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-1",attrs:{"items":_vm.fixtureWiring,"name":"quoteFixtureWiring","item-text":"description","item-value":"pk","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"fixture-wiring","data-cy":"rfq-fixture-wiring","rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteFixtureWiring,
                  vuexValue: _vm.quote.fixture_wiring,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: 'fixture_wiring',
                  value: _vm.formQuoteFixtureWiring,
                })}},model:{value:(_vm.formQuoteFixtureWiring),callback:function ($$v) {_vm.formQuoteFixtureWiring=$$v},expression:"formQuoteFixtureWiring"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Additional Services")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.additional_details)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{staticClass:"subtitle-1",attrs:{"label":"Test Instrumentation Selection and Integration","color":"secondary","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"check-instrumentation","data-cy":"rfq-fixture-check-instrumentation","rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formCheckInstrumentation,
                  vuexValue:
                    _vm.quote.test_instrumentation_selection_and_integration,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: 'test_instrumentation_selection_and_integration',
                  value: _vm.formCheckInstrumentation,
                })}},model:{value:(_vm.formCheckInstrumentation),callback:function ($$v) {_vm.formCheckInstrumentation=$$v},expression:"formCheckInstrumentation"}}),_c('v-checkbox',{staticClass:"subtitle-1",attrs:{"label":"Test Software Development","color":"secondary","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"check-software","data-cy":"rfq-fixture-check-software","rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formCheckSoftware,
                  vuexValue: _vm.quote.test_software_development,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: 'test_software_development',
                  value: _vm.formCheckSoftware,
                })}},model:{value:(_vm.formCheckSoftware),callback:function ($$v) {_vm.formCheckSoftware=$$v},expression:"formCheckSoftware"}}),_c('v-checkbox',{staticClass:"subtitle-1",attrs:{"label":"Custom Back Plate Connectors","color":"secondary","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"check-connectors","data-cy":"rfq-fixture-check-connectors","rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formCheckConnectors,
                  vuexValue: _vm.quote.custom_back_plate,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: 'custom_back_plate',
                  value: _vm.formCheckConnectors,
                })}},model:{value:(_vm.formCheckConnectors),callback:function ($$v) {_vm.formCheckConnectors=$$v},expression:"formCheckConnectors"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Additional Services Details")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',[_vm._v("Tell us anything else we should know about your additional services selections. A FixturFab employee will followup with you with next steps.")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{staticClass:"subtitle-1",attrs:{"name":"quoteServicesDetails","type":"text","auto-grow":"","flat":"","outlined":"","solo":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"services-details","data-cy":"rfq-fixture-additional-services-details","rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formQuoteServicesDetails,
                  vuexValue: _vm.quote.additional_services_details,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'additional_services_details',
                  value: _vm.formQuoteServicesDetails,
                })}},model:{value:(_vm.formQuoteServicesDetails),callback:function ($$v) {_vm.formQuoteServicesDetails=$$v},expression:"formQuoteServicesDetails"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"ml-10 my-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-btn',{attrs:{"x-large":"","color":"secondary","data-cy":"rfq-fixture-continue-button"},on:{"click":_vm.onSave}},[_vm._v(_vm._s((!_vm.inputValid ? "Save and " : "") + "Continue"))])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }