<template>
  <v-sheet flat>
    <v-card flat class="ma-1">
      {{ formValid }}
      <v-form lazy-validation ref="form">
        <v-card-text class="px-10">
          <v-row dense class="pa-0 mt-4 mx-0">
            <v-col cols="12" md="6">
              <span id="timeline" class="subtitle-1">Project Timeline *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.project_timeline"></span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="projectTimeline"
                name="quoteTimeline"
                item-text="description"
                item-value="pk"
                v-model="formQuoteTimeline"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="project-timeline"
                data-cy="rfq-fixture-project-timeline"
                @input="
                  updateUIState({
                    key: 'project_timeline',
                    value: formQuoteTimeline,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteTimeline,
                    vuexValue: quote.project_timeline,
                  })
                "
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Project Timeline Details</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.project_timeline_details"></span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-textarea
                name="quoteTimelineDetails"
                type="text"
                auto-grow
                flat
                outlined
                solo
                dense
                class="subtitle-1"
                v-model="formQuoteTimelineDetails"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="project-timeline-details"
                data-cy="rfq-fixture-project-timeline-details"
                @input="
                  updateUIState({
                    key: 'project_timeline_details',
                    value: formQuoteTimelineDetails,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formQuoteTimelineDetails,
                    vuexValue: quote.project_timeline_details,
                  })
                "
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Estimated Volumes *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.estimated_volumes"></span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-text-field
                name="quoteEstimatedVolume"
                type="number"
                v-model="formQuoteEstimatedVolume"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="estimated-volumes"
                data-cy="rfq-fixture-estimated-volumes"
                :onInput="onInputString()"
                @input="
                  updateUIState({
                    key: 'estimated_volumes',
                    value: +formQuoteEstimatedVolume,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteEstimatedVolume,
                    vuexValue: quote.estimated_volumes,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Number of Fixtures *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.number_of_fixtures"></span>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <v-text-field
                name="quoteNumberOfFixtures"
                type="number"
                v-model="formQuoteNumberOfFixtures"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="number-of-fixtures"
                data-cy="rfq-fixture-number-of-fixtures"
                :onInput="onInputString()"
                @input="
                  updateUIState({
                    key: 'number_of_fixtures',
                    value: +formQuoteNumberOfFixtures,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteNumberOfFixtures,
                    vuexValue: quote.number_of_fixtures,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <v-alert
                v-if="quote.number_of_unique_duts > 1"
                color="warning"
                dense
                outlined
                type="info"
                class="ma-0 subtitle-2"
                >A panelized fixture is only available when there is a single
                unique DUT in the fixture.</v-alert
              >
              <v-checkbox
                v-model="formCheckPanelize"
                label="Would you like a panelize fixture?"
                color="secondary"
                :disabled="quote.number_of_unique_duts > 1 || (!isQuoteIncomplete && !isAdmin)"
                dense
                data-test="check-panelize"
                data-cy="rfq-fixture-check-panelize"
                @change="
                  updateUIState({
                    key: 'panelized_fixture',
                    value: formCheckPanelize,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formCheckPanelize,
                    vuexValue: quote.panelized_fixture,
                  })
                "
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <transition name="slide">
            <div class="mt-15" v-if="formCheckPanelize">
              <v-row dense class="pa-0 ma-0">
                <v-col cols="12" md="6">
                  <span class="subtitle-1">DUTs in Panel</span>
                </v-col>
              </v-row>
              <v-row dense class="ma-0 pa-0">
                <v-col cols="12" md="6">
                  <span v-html="fieldDescriptions.duts_in_panel"></span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    name="quoteDutsInPanel"
                    type="number"
                    v-model="formQuoteDutsInPanel"
                    outlined
                    dense
                    class="subtitle-1"
                    :disabled="!isQuoteIncomplete && !isAdmin"
                    data-test="duts-in-panel"
                    data-cy="rfq-fixture-duts-in-panel"
                    :onInput="onInputString()"
                    @input="
                      updateUIState({
                        key: 'duts_in_panel',
                        value: formQuoteDutsInPanel,
                      })
                    "
                    :rules="
                      hybridRules({
                        required: false,
                        formValue: formQuoteDutsInPanel,
                        vuexValue: quote.duts_in_panel,
                      })
                    "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-row dense class="pa-0 ma-0">
                        <v-col cols="12">
                          <span class="subtitle-1">DUTs per row</span>
                        </v-col>
                      </v-row>
                      <v-row dense class="pa-0 ma-0">
                        <v-col cols="12">
                          <span v-html="fieldDescriptions.duts_per_row"></span>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            name="quoteDutsPerRow"
                            type="number"
                            v-model="formQuoteDutsPerRow"
                            outlined
                            dense
                            class="subtitle-1"
                            :disabled="!isQuoteIncomplete && !isAdmin"
                            data-test="duts-per-row"
                            data-cy="rfq-fixture-duts-per-row"
                            :onInput="onInputString()"
                            @input="
                              updateUIState({
                                key: 'duts_per_row',
                                value: formQuoteDutsPerRow,
                              })
                            "
                            :rules="
                              hybridRules({
                                required: false,
                                formValue: formQuoteDutsPerRow,
                                vuexValue: quote.duts_per_row,
                              })
                            "
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row dense class="pa-0 ma-0">
                        <v-col cols="12">
                          <span class="subtitle-1">DUTs per column</span>
                        </v-col>
                      </v-row>
                      <v-row dense class="pa-0 ma-0">
                        <v-col cols="12">
                          <span
                            v-html="fieldDescriptions.duts_per_column"
                          ></span>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            name="quoteDutsPerColumn"
                            type="number"
                            v-model="formQuoteDutsPerColumn"
                            outlined
                            dense
                            class="subtitle-1"
                            :disabled="!isQuoteIncomplete && !isAdmin"
                            data-test="duts-per-column"
                            data-cy="rfq-fixture-duts-per-column"
                            :onInput="onInputString()"
                            @input="
                              updateUIState({
                                key: 'duts_per_column',
                                value: formQuoteDutsPerColumn,
                              })
                            "
                            :rules="
                              hybridRules({
                                required: false,
                                formValue: formQuoteDutsPerColumn,
                                vuexValue: quote.duts_per_column,
                              })
                            "
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense class="pa-0 ma-0">
                <v-col cols="12" md="6">
                  <span class="subtitle-1">Panel Details</span>
                </v-col>
              </v-row>
              <v-row dense class="ma-0 pa-0">
                <v-col cols="12" md="6">
                  <span v-html="fieldDescriptions.panel_details"></span>
                </v-col>
              </v-row>
              <v-row dense class="my-2">
                <v-col cols="12" md="6">
                  <v-textarea
                    name="quotePanelDetails"
                    type="text"
                    auto-grow
                    flat
                    outlined
                    solo
                    dense
                    class="subtitle-1"
                    v-model="formQuotePanelDetails"
                    :disabled="!isQuoteIncomplete && !isAdmin"
                    data-test="panel-details"
                    data-cy="rfq-fixture-panel-details"
                    @input="
                      updateUIState({
                        key: 'panel_details',
                        value: formQuotePanelDetails,
                      })
                    "
                    :rules="
                      hybridRules({
                        required: false,
                        formValue: formQuotePanelDetails,
                        vuexValue: quote.panel_details,
                      })
                    "
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </div>
          </transition>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Wiring *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.fixture_wiring"></span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="fixtureWiring"
                name="quoteFixtureWiring"
                item-text="description"
                item-value="pk"
                v-model="formQuoteFixtureWiring"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="fixture-wiring"
                data-cy="rfq-fixture-wiring"
                @change="
                  updateUIState({
                    key: 'fixture_wiring',
                    value: formQuoteFixtureWiring,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteFixtureWiring,
                    vuexValue: quote.fixture_wiring,
                  })
                "
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Additional Services</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.additional_details"></span>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="formCheckInstrumentation"
                label="Test Instrumentation Selection and Integration"
                color="secondary"
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="check-instrumentation"
                data-cy="rfq-fixture-check-instrumentation"
                @change="
                  updateUIState({
                    key: 'test_instrumentation_selection_and_integration',
                    value: formCheckInstrumentation,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formCheckInstrumentation,
                    vuexValue:
                      quote.test_instrumentation_selection_and_integration,
                  })
                "
              >
              </v-checkbox>
              <v-checkbox
                v-model="formCheckSoftware"
                label="Test Software Development"
                color="secondary"
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="check-software"
                data-cy="rfq-fixture-check-software"
                @change="
                  updateUIState({
                    key: 'test_software_development',
                    value: formCheckSoftware,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formCheckSoftware,
                    vuexValue: quote.test_software_development,
                  })
                "
              >
              </v-checkbox>
              <v-checkbox
                v-model="formCheckConnectors"
                label="Custom Back Plate Connectors"
                color="secondary"
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="check-connectors"
                data-cy="rfq-fixture-check-connectors"
                @change="
                  updateUIState({
                    key: 'custom_back_plate',
                    value: formCheckConnectors,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formCheckConnectors,
                    vuexValue: quote.custom_back_plate,
                  })
                "
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Additional Services Details</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span
                >Tell us anything else we should know about your additional
                services selections. A FixturFab employee will followup with you
                with next steps.</span
              >
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-textarea
                name="quoteServicesDetails"
                type="text"
                auto-grow
                flat
                outlined
                solo
                dense
                class="subtitle-1"
                v-model="formQuoteServicesDetails"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="services-details"
                data-cy="rfq-fixture-additional-services-details"
                @input="
                  updateUIState({
                    key: 'additional_services_details',
                    value: formQuoteServicesDetails,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formQuoteServicesDetails,
                    vuexValue: quote.additional_services_details,
                  })
                "
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="ml-10 my-5">
            <v-col cols="12" md="6">
              <v-row>
                <v-btn x-large color="secondary" @click="onSave" data-cy="rfq-fixture-continue-button">{{
                  (!inputValid ? "Save and " : "") + "Continue"
                }}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-sheet>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "FixtureDetailsPage",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    quoteUser: {
      type: Object,
      default: null,
    },
    projectTimeline: {
      type: Array,
      default: null,
    },
    fixtureWiring: {
      type: Array,
      default: null,
    },
    fieldDescriptions: {
      type: Object,
      default: null,
    },
    dutDetails: {
      type: Array,
      default: null,
    },
    inputValid: {
      type: Boolean,
      default: false,
    },
    previousValid: {
      type: Boolean,
      default: false,
    },
    isQuoteIncomplete: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formQuoteTimeline: this.quote ? this.quote.project_timeline : null,
      formQuoteTimelineDetails: this.quote
        ? this.quote.project_timeline_details
        : null,
      formQuoteEstimatedVolume: this.quote
        ? this.quote.estimated_volumes > 0
          ? this.quote.estimated_volumes
          : this.quote.project_type === 1
          ? 500
          : 10000
        : null,
      formQuoteNumberOfFixtures: this.quote
        ? this.quote.number_of_fixtures
        : null,
      formCheckPanelize: this.quote ? this.quote.panelized_fixture : false,
      formQuoteDutsInPanel: this.quote ? this.quote.duts_in_panel : null,
      formQuoteDutsPerRow: this.quote ? this.quote.duts_per_row : null,
      formQuoteDutsPerColumn: this.quote ? this.quote.duts_per_column : null,
      formQuotePanelDetails: this.quote ? this.quote.panel_details : null,
      formQuoteFixtureWiring: this.quote ? this.quote.fixture_wiring : null,
      formCheckInstrumentation: this.quote
        ? this.quote.test_instrumentation_selection_and_integration
        : false,
      formCheckSoftware: this.quote
        ? this.quote.test_software_development
        : false,
      formCheckConnectors: this.quote ? this.quote.custom_back_plate : false,
      formQuoteServicesDetails: this.quote
        ? this.quote.additional_services_details
        : null,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
      },
      MAX_NUMBER_INPUT: 2147483647
    };
  },
  computed: {
    formValid() {
      return this.$refs.form?.validate();
    },
    computedVolumes() {
      if (this.quote) {
        return this.quote.estimated_volumes > 0
          ? this.quote.estimated_volumes
          : this.quote.project_type == 1
          ? 500
          : 10000;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      saveQuote: "quotes/saveQuote",
      saveDutDetails: "quotes/saveDutDetail",
      getDutDetails: "quotes/getDutDetails",
      removeDutDetail: "quotes/deleteDutDetail",
      toggleLoading: "ui/loading",
    }),
    hybridRules({ required }) {
      const rules = [];
      if (required) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    async onSave() {
      if (!this.$refs.form.validate()) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }

      if (this.inputValid) {
        this.onNextTab();
        return;
      }

      const actionPayload = {
        editMode: this.editMode || Object.keys(this.quote).length > 0,
        id:
          this.editMode || Object.keys(this.quote).length > 0
            ? this.quote.id
            : null,
        data: {
          user: this.quoteUser.pk,
          company:
            this.quoteUser.groups.length > 0
              ? this.quoteUser.groups[0].id
              : null,
          project_timeline: this.formQuoteTimeline,
          project_timeline_details: this.formQuoteTimelineDetails,
          estimated_volumes: this.formQuoteEstimatedVolume,
          number_of_fixtures: this.formQuoteNumberOfFixtures,
          panelized_fixture: this.formCheckPanelize,
          duts_in_panel: this.formCheckPanelize
            ? this.formQuoteDutsInPanel
            : null,
          duts_per_row: this.formCheckPanelize
            ? this.formQuoteDutsPerRow
            : null,
          duts_per_column: this.formCheckPanelize
            ? this.formQuoteDutsPerColumn
            : null,
          panel_details: this.formCheckPanelize
            ? this.formQuotePanelDetails
            : "",
          fixture_wiring: this.formQuoteFixtureWiring,
          test_instrumentation_selection_and_integration: this
            .formCheckInstrumentation,
          test_software_development: this.formCheckSoftware,
          custom_back_plate: this.formCheckConnectors,
          additional_services_details: this.formQuoteServicesDetails,
        },
      };
      this.$emit("onSaveFixtureDetails", actionPayload);
    },
    async onSaveQuoteData(payload) {
      this.$emit("onSaveQuoteData", payload);
    },
    onNextTab() {
      this.$emit("nextTab");
    },
    onPreviousTab() {
      this.$emit("previousTab");
    },
    updateUIState(payload) {
      this.$emit("onUpdateUIState", payload);
    },
    updateTimeline({ key, payload }) {
      this[key] = payload;
    },
    updatePanelize(payload) {
      this.formCheckPanelize = payload;
      this.formQuoteDutsInPanel = this.formQuoteDutsPerRownull = this.formQuoteDutsPerColumn = null;
      this.formQuotePanelDetails = "";
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
  mounted() {
    this.$refs.form.validate();
    this.$emit("scrollToTop");
  },
};
</script>
